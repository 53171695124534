(function () {
  angular.module('kmi.lms.chem.user.edit').component('editUserAccountComponent', {
    template: require('ajs/custom_modules/chem/user/edit/edit-account.html').default,
    controller: EditUserAccountController,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  });

  /* @ngInject */
  function EditUserAccountController($scope, backUrlService) {
    var vm = this;

    vm.validationPending = false;
    vm.save = save;
    vm.cancel = cancel;

    activate();

    function activate() {
      $scope.$on('event:user.saved', function () {
        cancel();
      });

      $scope.$on('event:validation.pending', function (event, pending) {
        vm.validationPending = pending;
      });
    }

    function save() {
      $scope.$broadcast('event:user.save');
    }

    function cancel() {
      backUrlService.goBack();
    }
  }
})();
