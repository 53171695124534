(function () {
  angular
    .module('kmi.lms.chem.home', ['kmi.lms.core', 'kmi.lms.components', 'kmi.lms.integration'])
    .config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.home', {
        url: '^/home?state',
        component: 'chemHome',
        params: {
          state: null,
        },
        data: {
          label: 'Home',
          availableFor: ['regularUser', 'admin'],
        },
      })
      .state('main.welcome', {
        url: '/welcome',
        component: 'chemWelcomePageComponent',
        data: {
          label: 'Home',
          availableFor: ['anonymous'],
        },
      });
  }
})();
