(function () {
  angular
    .module('kmi.lms.chem.user.edit', ['kmi.lms.user.common', 'kmi.lms.user.components', 'http-auth-interceptor'])
    .constant('chemUserConstant', {
      COMPANY_ATTRIBUTE_ID: 12,
    })
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('prompt.userRegistration', {
        url: '/register',
        component: 'createAccountComponent',
        data: {
          label: 'Registration',
          availableFor: ['anonymous'],
        },
        resolve: {
          user: function (User) {
            return User.newUser({
              country: 'US',
            });
          },
        },
      })
      .state('prompt.userRegistration.policies', {
        url: '/policies',
        component: 'policiesComponent',
        data: {
          label: 'Policies',
          availableFor: ['anonymous'],
        },
      })
      .state('edit.account', {
        url: '/account?activeView',
        component: 'editUserAccountComponent',
        data: {
          label: 'Account edit',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: {
          user: function (User) {
            return User.get();
          },
        },
      });
  }
})();
