(function () {
  angular.module('kmi.lms.core').config(coreModuleConfig);

  function coreModuleConfig($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/u',
        template: require('ajs/custom_modules/chem/app/base-view.html').default,
        abstract: true,
      })
      .state('mainPrint', {
        url: '/p',
        template: require('ajs/custom_modules/chem/app/base-view-print.html').default,
        abstract: true,
      })
      .state('prompt', {
        url: '/prompt',
        component: 'promptView',
        abstract: true,
      });
  }

  angular.module('elmsSpaApp').run(moduleRun);

  function moduleRun($templateCache) {
    $templateCache.put(
      'custom_modules/chem/contact/contact.html',
      require('ajs/custom_modules/chem/contact/contact.html').default,
    );
  }
})();
