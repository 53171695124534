(function () {
  angular
    .module('kmi.lms.chem.user.edit')
    .constant('DefaultSelfRegGroup', 5001040) // "Half Day Hazmat"
    .component('createAccountComponent', {
      template: require('ajs/custom_modules/chem/user/edit/self-registration.html').default,
      controller: CreateAccountController,
      controllerAs: 'vm',
      bindings: {
        user: '<',
      },
    });

  function CreateAccountController($scope, $state, elmsAuthService, navigationService, backUrlService) {
    var vm = this;

    vm.validationPending = false;
    vm.saveAccount = saveAccount;
    vm.showForm = showForm;
    vm.backUrl = backUrlService;

    activate();

    function activate() {
      $scope.$on('event:user.saved', function () {
        $scope.$on('elmsAuthService.notification.login', function () {
          navigationService.transitionToTargetState();
        });

        elmsAuthService.login({
          username: vm.user.loginName,
          password: vm.user.password,
        });
      });

      $scope.$on('event:validation.pending', function (event, pending) {
        vm.validationPending = pending;
      });
    }

    function saveAccount() {
      $scope.$broadcast('event:user.save');
    }

    function showForm() {
      return $state.is('prompt.userRegistration');
    }
  }
})();
