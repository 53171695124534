import {
  configureApplicationRuntimeSettings,
  configureApplicationSettings,
} from '../../modules/app/configuration/application-configuration';

require('ajs/vendors');
require('ajs/modules/app/models/class.ts');
require('ajs/global-dependencies.ts');
require('ajs/global-scope.ts');
require('ajs/modules/app/_init.ts');

declare const angular: angular.IAngularStatic;

angular
  .module('elmsSpaApp', [
    'ui.router',
    'ui.router.upgrade',
    'http-auth-interceptor',
    'ui.bootstrap',
    'angular-ladda',
    'ngCookies',
    'ngAnimate',
    'ngResource',
    'ui.mask',
    'ngSanitize',
    'ngAria',
    'globalDependencies',
    'kmi.lms.services',
    'blueimp.fileupload',
    'kmi.lms.core',
    'kmi.lms.core.login',
    'kmi.lms.components',
    'kmi.lms.dictionary',
    'kmi.lms.chem.home',
    'kmi.lms.user.certificates',
    'kmi.lms.search',
    'kmi.lms.user',
    'kmi.lms.user.notifications',
    'kmi.lms.course.common',
    'kmi.lms.quiz',
    'kmi.lms.course.quizzes',
    'kmi.lms.course.details',
    'kmi.lms.course.registrations',
    'kmi.lms.user.common',
    'kmi.lms.user.learning',
    'kmi.lms.chem.user.edit',
    'kmi.lms.passwords',
    'kmi.lms.course.purchase',
  ])
  .config(configureApplicationSettings)
  .run(configureApplicationRuntimeSettings);

require('ajs/core-modules');

require('ajs/modules/course/purchase/_init.ts');
require('ajs/modules/course/purchase/history/course-purchase-history-directive.ts');
require('ajs/modules/course/purchase/history/course-receipt-component.ts');
require('ajs/custom_modules/chem/user/edit/_init.ts');
require('ajs/custom_modules/chem/user/edit/components/edit-user-form-directive.ts');
require('ajs/custom_modules/chem/user/edit/create-account-component.ts');
require('ajs/custom_modules/chem/user/edit/edit-account-component.ts');
require('ajs/custom_modules/chem/user/learning/_init.ts');
require('ajs/custom_modules/chem/_init.ts');
require('ajs/custom_modules/chem/home/_init.ts');
require('ajs/custom_modules/chem/home/home-component.ts');
require('ajs/custom_modules/chem/home/welcome-page-component.ts');
require('ajs/modules/user/notifications/grouped/notifications-bell-directive.ts');
require('ajs/modules/user/notifications/grouped/notifications-list-component.ts');
require('ajs/modules/user/notifications/grouped/notifications-alert-component.ts');
require('ajs/modules/user/notifications/grouped/modal/notifications-component.ts');

require('src/projects/chem/index.module.ts');
