(function () {
  angular.module('kmi.lms.chem.user.edit').directive('editUserForm', editUser);

  function editUser() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        user: '=',
        selfEdit: '=',
      },
      template: require('ajs/custom_modules/chem/user/edit/components/edit-user-form.html').default,
      controller: EditUserFormController,
    };
  }

  /* @ngInject */
  function EditUserFormController(
    $scope,
    notificationService,
    $q,
    $interval,
    geoService,
    userPasswordService,
    chemUserConstant,
    DefaultSelfRegGroup,
    $http,
  ) {
    var defaultCountry = 'US';

    $scope.submitted = false;
    $scope.addressPattern = /^((?!(po|p.o.*)\s*\W*box).)*$/i;
    $scope.groups = {
      company: null,
      manager: null,
    };

    activate();

    function activate() {
      initDefaultUserData();

      $q.all([geoService.getStates(defaultCountry).toPromise().then(bindStates)]).then(
        function initNewUserDefaultValues() {
          if (!$scope.user.id) {
            $scope.user.country = defaultCountry;
          }
        },
      );
    }

    function bindStates(states) {
      $scope.states = states;
    }

    function findCompanyAttr() {
      $scope.user.attributes = $scope.user.attributes || [];
      var company = $scope.user.attributes.find(function (item) {
        return item.id === chemUserConstant.COMPANY_ATTRIBUTE_ID;
      });

      if (!company) {
        company = {
          id: chemUserConstant.COMPANY_ATTRIBUTE_ID,
          value: '',
        };
        $scope.user.attributes.push(company);
      }
      return company;
    }

    function initDefaultUserData() {
      $scope.company = findCompanyAttr();
      $scope.passwordConfirmation = $scope.user.password;

      if (!$scope.user.id && $scope.selfEdit) {
        $scope.user.setGroups([DefaultSelfRegGroup]);
      }
    }

    //actions
    $scope.resetPassword = function () {
      if ($scope.selfEdit) {
        userPasswordService.changePasswordDialog($scope.user);
      } else {
        userPasswordService.resetPasswordDialog($scope.user);
      }
    };

    $scope.providePassword = function () {
      userPasswordService.providePasswordDialog($scope.user);
    };

    $scope.groupsChange = function () {
      $scope.user.setGroups([$scope.groups.manager ? $scope.groups.manager : $scope.groups.company]);
    };

    $scope.submitForm = function () {
      $scope.submitted = true;
      $scope.$emit('event:validation.pending', true);

      let validationPending = $interval(function () {
        if (!$scope.userForm.$pending) {
          if (!$http.pendingRequests.length) {
            $interval.cancel(validationPending);
            if ($scope.userForm.$valid) {
              $scope.user.save().then(
                () => {
                  $scope.$emit('event:user.saved', $scope.user);
                },
                function () {
                  notificationService.error('An error occurred saving user data. Please try again later.', 5e3);
                  $scope.$emit('event:validation.pending', false);
                },
              );
            } else {
              notificationService.error('Please fill in all required fields', 2e3);
              $scope.$emit('event:validation.pending', false);
            }
          }
        }
      }, 100);
    };
  }
})();
