(function () {
  /**
   * @ngdoc controller
   * @name kmi.lms.chem.home.controller:Сhem.HomePageController
   * @description
   * Provides welcome services for anonymous users
   *
   * @requires $q
   * @requires Course
   * @requires elms
   * @requires vimeoVideoService
   *
   */
  angular.module('kmi.lms.chem.home').component('chemWelcomePageComponent', {
    template: require('ajs/custom_modules/chem/home/welcome.html').default,
    controllerAs: 'vm',
    controller: WelcomePageComponentController,
  });

  /* @ngInject */
  function WelcomePageComponentController($q, $state, Course, elms, markdownService, vimeoVideoService, _) {
    var vm = this;

    vm.courses = [];
    vm.formatPrice = elms.formatPrice;
    vm.videoPath = vimeoVideoService.buildVideoEmbedPath(191667625, { title: false, byline: false, portrait: false });
    vm.buyCourse = buyCourse;

    activate();

    function activate() {
      $q.all([
        Course.get({ courseId: 1000005121 }).$promise.catch(function () {
          return null;
        }),
        Course.get({ courseId: 1000005122 }).$promise.catch(function () {
          return null;
        }),
        Course.get({ courseId: 1000006182 }).$promise.catch(function () {
          return null;
        }),
      ]).then(function (data) {
        _.each(data, function (item) {
          if (item) {
            vm.courses.push({
              id: parseInt(item.id),
              name: item.name,
              description: markdownService.toHtml(item.description),
              price: item.price,
            });
          }
        });
      });
    }

    function buyCourse(course, price) {
      $state.go('main.checkout', { courseId: course.id, price: price });
    }
  }
})();
